/* jshint ignore:start */
import { GestureEventListeners } from "@polymer/polymer/lib/mixins/gesture-event-listeners.js";
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";

const e = window.nds.getSlottedChildren;
const t = "cspl-social-bar__buttons--visible";

/**
/**
 * `cs-social-bar` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSocialBar extends GestureEventListeners(PolymerElement) {
	static get properties() {
		return {
			textAlign: {
				type: String,
				value: "left",
				reflectToAttribute: !0,
			},
			textColor: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			theme: {
				type: String,
				value: "light",
				reflectToAttribute: !0,
			},
			type: {
				type: String,
				value: "horizontal",
				reflectToAttribute: !0,
			},
			titleText: String,
			expanded: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			spacingTop: {
				type: String,
				value: "small",
				reflectToAttribute: !0,
			},
			spacingBottom: {
				type: String,
				value: "small",
				reflectToAttribute: !0,
			},
			_showActionButtons: {
				type: Boolean,
				value: !1,
			},
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-typography-paragraphs"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style include="cs-shared-styles-typography-labels"></style>
			<style include="cs-social-button-shared-styles"></style>
			<style>
				:host {
					--action-button-icon-light-fill: #a3a3a3;
					--action-button-icon-dark-fill: #585858;
					--expand-animation-duration: 0.5s;
					display: flex;
					flex-direction: column;
					justify-content: center;
					position: relative;
					z-index: 1;
				}

				:host([text-align="left"]) {
					align-items: flex-start;
				}

				:host([text-align="center"]) {
					align-items: center;
				}

				:host([text-align="right"]) {
					align-items: flex-end;
				}

				:host([type="vertical"]) {
					display: inline-flex;
				}

				.cspl-social-bar__content {
					display: block;
					white-space: nowrap;
					width: 100%;
				}

				:host([text-align="center"]) .cspl-social-bar__content {
					text-align: center;
				}

				:host([text-align="right"]) .cspl-social-bar__content {
					text-align: right;
				}

				:host([type="vertical"]) .cspl-social-bar__content {
					flex-direction: column;
					overflow-x: visible;
				}

				:host([type="horizontal"]) .cspl-social-bar__content {
					flex-direction: row;
					overflow-x: auto;
				}

				.cspl-social-bar__title {
					margin-bottom: 6px;
					margin-top: 0;
				}

				:host([text-color="light"]) .cspl-social-bar__title {
					color: var(--cs-light-color);
				}

				:host([text-color="dark"]) .cspl-social-bar__title {
					color: var(--cs-dark-color);
				}

				@media only screen and (min-width: 768px) {
					:host([type="horizontal"]) .cspl-social-bar__title {
						margin-bottom: 24px;
					}
				}

				.cspl-social-bar__actions,
				.cspl-social-bar__buttons {
					align-items: flex-start;
					justify-content: flex-start;
					margin: 0;
					padding: 4px;
				}

				:host([type="horizontal"]) .cspl-social-bar__actions,
				:host([type="horizontal"]) .cspl-social-bar__buttons {
					display: inline-flex;
					flex-direction: row;
					vertical-align: top;
				}

				:host([type="vertical"]) .cspl-social-bar__actions,
				:host([type="vertical"]) .cspl-social-bar__buttons {
					display: flex;
					flex-direction: column;
				}

				.cspl-social-bar__buttons {
					overflow: hidden;
					transition-duration: var(--expand-animation-duration);
					transition-property: max-height, max-width;
					transition-timing-function: ease-in-out;
				}

				.cspl-social-bar__buttons--visible {
					overflow: visible;
				}

				:host([type="horizontal"]) .cspl-social-bar__buttons {
					max-width: 139px;
				}

				@media only screen and (min-width: 768px) {
					:host([type="horizontal"]) .cspl-social-bar__buttons {
						max-width: 196px;
					}
				}

				:host([type="horizontal"][expanded]) .cspl-social-bar__buttons {
					max-width: 454px;
				}

				@media only screen and (min-width: 768px) {
					:host([type="horizontal"][expanded]) .cspl-social-bar__buttons {
						max-width: 644px;
					}
				}

				:host([type="vertical"]) .cspl-social-bar__buttons {
					max-height: 139px;
				}

				@media only screen and (min-width: 768px) {
					:host([type="vertical"]) .cspl-social-bar__buttons {
						max-height: 172px;
					}
				}

				:host([type="vertical"][text-align="center"]) .cspl-social-bar__buttons {
					align-items: center;
				}

				:host([type="vertical"][text-align="right"]) .cspl-social-bar__buttons {
					align-items: flex-end;
				}

				:host([type="vertical"][expanded]) .cspl-social-bar__buttons {
					max-height: 454px;
				}

				@media only screen and (min-width: 768px) {
					:host([type="vertical"][expanded]) .cspl-social-bar__buttons {
						max-height: 564px;
					}
				}

				:host([type="horizontal"]) .cspl-social-bar__actions {
					padding-left: 0;
				}

				:host([type="vertical"]) .cspl-social-bar__actions {
					padding-left: 4px;
					padding-top: 0;
				}

				@media only screen and (min-width: 768px) {
					:host([type="horizontal"]) .cspl-social-bar__actions {
						margin-left: -4px;
					}

					:host([type="vertical"]) .cspl-social-bar__actions {
						margin-top: -4px;
					}
				}

				:host([type="vertical"][text-align="center"]) .cspl-social-bar__actions {
					align-items: center;
				}

				:host([type="vertical"][text-align="right"]) .cspl-social-bar__actions {
					align-items: flex-end;
				}

				.cspl-social-bar__action {
					border: 2px solid var(--action-button-icon-light-fill);
				}

				:host([theme="dark"]) .cspl-social-bar__action {
					border: 2px solid var(--action-button-icon-dark-fill);
				}

				.cspl-social-bar__icon {
					fill: var(--action-button-icon-light-fill);
				}

				:host([theme="dark"]) .cspl-social-bar__icon {
					fill: var(--action-button-icon-dark-fill);
				}

				:host([type="horizontal"]) .cspl-social-bar__buttons ::slotted(*) {
					margin-right: 5px;
				}

				:host([type="horizontal"]) .cspl-social-bar__buttons ::slotted(:last-child) {
					margin-right: 0;
				}

				@media only screen and (min-width: 768px) {
					:host([type=horizontal]) .cspl-social-bar__buttons ::slotted(*),
                :host([type=horizontal]) .cspl-social-bar__buttons ::slotted(:nth-child(n+4): last-child) {
						margin-right: 24px;
					}
				}

				:host([type="vertical"]) .cspl-social-bar__buttons ::slotted(*) {
					margin-bottom: 5px;
				}

				:host([type="vertical"]) .cspl-social-bar__buttons ::slotted(:last-child) {
					margin-bottom: 0;
				}

				@media only screen and (min-width: 768px) {
					:host([type=vertical]) .cspl-social-bar__buttons ::slotted(*),
                :host([type=vertical]) .cspl-social-bar__buttons ::slotted(:nth-child(n+4): last-child) {
						margin-bottom: 16px;
					}
				}

				:host([expanded]) .cspl-social-bar__buttons--visible ::slotted(:nth-child(n + 4)) {
					display: flex;
				}

				:host .cspl-social-bar__buttons--visible ::slotted(:nth-child(n + 4)) {
					display: none;
				}</style
			><template is="dom-if" if="{{ titleText }}">
				<h2 class="cspl-social-bar__title l2">[[ titleText ]]</h2>
			</template>
			<div class="cspl-social-bar__content">
				<div class="cspl-social-bar__buttons" id="buttons" on-direct-link-copying="_onDirectLinkCopying">
					<slot name="social-share-item"></slot>
				</div>
				<ul class="cspl-social-bar__actions">
					<template is="dom-if" if="{{ _computeCloseButtonVisibility(_showActionButtons, expanded) }}">
						<li class="cspl-social-bar__action cspl-social-button" on-tap="_close">
							<iron-icon class="cspl-social-bar__icon" icon="cs-action-16:close"></iron-icon>
						</li> </template
					><template is="dom-if" if="{{ _computeExpandButtonVisibility(_showActionButtons, expanded) }}">
						<li class="cspl-social-bar__action cspl-social-button" on-tap="_expand">
							<iron-icon class="cspl-social-bar__icon" icon="cs-action-16:plus"></iron-icon>
						</li>
					</template>
				</ul>
			</div> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	initSocialBar() {
		const t = e(this);
		t.forEach((e) => {
			e.setAttribute("theme", this.theme);
		}),
			(this._showActionButtons = !!(t.length > 3)); // jshint ignore:line
	}
	_onDirectLinkCopying(e) {
		e.currentTarget.classList.toggle(t);
	}
	_expand() {
		this.toggleAttribute("expanded", !0);
	}
	_close() {
		this.$.buttons.classList.toggle(t, !1), this.toggleAttribute("expanded", !1); // jshint ignore:line
	}
	_computeCloseButtonVisibility() {
		return this._showActionButtons && this.expanded;
	}
	_computeExpandButtonVisibility() {
		return this._showActionButtons && !this.expanded;
	}
	connectedCallback() {
		super.connectedCallback(), setTimeout(this.initSocialBar.bind(this)), this.shadowRoot.addEventListener("slotchange", this.initSocialBar.bind(this)); // jshint ignore:line
	}
}

customElements.define("cs-social-bar", CsSocialBar);
