import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';
import { CsModalInvokerMixin } from './mixins/cs-modal-invoker-mixin.js';

const t = CsMixin;
const a = CsGoogleAnalyticsMixin;
const r = CsModalInvokerMixin;

/**
 * `cs-cta` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsCta extends t(a(r(CsElements))) {
  static get properties() {
    return {
      linkUrl: String,
      linkTitle: String,
      linkText: String,
      iconType: String,
      customIcon: {
        type: String,
        value: null,
      },
      fillColor: {
        type: String,
        value: 'none',
        reflectToAttribute: !0,
      },
      textColor: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      type: {
        type: String,
        value: 'standard',
        reflectToAttribute: !0,
      },
      tooltip: String,
      icon: {
        type: String,
        value: 'none',
        reflectToAttribute: !0,
      },
      targetOs: {
        type: String,
        value: null,
      },
      image: String,
      alt: {
        type: String,
        value: ' ',
      },
      fullWidth: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      intent: {
        type: String,
        value: 'misc',
      },
      context: {
        type: String,
        observer: '_setGaEvent',
      },
      emphasis: {
        type: String,
        value: 'primary',
        reflectToAttribute: !0,
      },
      loading: {
        type: Boolean,
        readOnly: true,
        notify: true,
        value: false,
      },
      target: String,
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
      <style>
        @keyframes move-left {
          0% {
            transform: rotate(90deg) translateY(0);
          }

          50% {
            transform: rotate(90deg) translateY(10px);
          }

          100% {
            transform: rotate(90deg) translateY(0);
          }
        }

        @keyframes move-right {
          0% {
            transform: rotate(-90deg) translateY(0);
          }

          50% {
            transform: rotate(-90deg) translateY(10px);
          }

          100% {
            transform: rotate(-90deg) translateY(0);
          }
        }

        :host {
          display: flex;
          margin-bottom: 16px;
          position: relative;
          transition: opacity var(--cs-interface-expansion);
          width: 100%;
        }

        :host(:last-child) {
          margin-bottom: 0;
        }

        :host([wf-loading]) {
          opacity: 0;
        }

        @media only screen and (min-width: 640px) {
          :host {
            display: inline-flex;
            width: auto;
          }
        }

        @media only screen and (min-width: 768px) {
          :host {
            margin-right: 24px;
          }

          :host(:last-child) {
            margin-bottom: 16px;
            margin-right: 0;
          }
        }

        .cspl-cta {
          align-items: center;
          color: var(--cs-dark-color);
          cursor: pointer;
          display: flex;
          justify-content: center;
          position: relative;
          text-decoration: none;
          width: 100%;
        }

        .cspl-cta:focus {
          outline: 0;
        }

        :host([text-color='light']) .cspl-cta,
        :host([text-color='light']) .cspl-cta:hover {
          color: var(--cs-light-color);
          fill: var(--cs-light-color);
        }

        :host([text-color='brand']) .cspl-cta,
        :host([text-color='brand']) .cspl-cta:hover {
          color: var(--cs-theme-color);
          fill: var(--cs-theme-color);
        }

        :host([text-color='cs-access-brand']) .cspl-cta,
        :host([text-color='cs-access-brand']) .cspl-cta:hover {
          color: var(--cs-access-brand-color);
          fill: var(--cs-access-brand-color);
        }

        :host([text-color='origin-brand']) .cspl-cta,
        :host([text-color='origin-brand']) .cspl-cta:hover {
          color: var(--origin-brand-color);
          fill: var(--origin-brand-color);
        }

        :host([text-color='xbox-brand']) .cspl-cta,
        :host([text-color='xbox-brand']) .cspl-cta:hover {
          color: var(--xbox-brand-color);
          fill: var(--xbox-brand-color);
        }

        :host([text-color='playstation-brand']) .cspl-cta,
        :host([text-color='playstation-brand']) .cspl-cta:hover {
          color: var(--playstation-brand-color);
          fill: var(--playstation-brand-color);
        }

        :host([text-color='nintendo-brand']) .cspl-cta,
        :host([text-color='nintendo-brand']) .cspl-cta:hover {
          color: var(--nintendo-brand-color);
          fill: var(--nintendo-brand-color);
        }

        :host([type|='standard']) {
          min-width: 136px;
          z-index: 0;
        }

        @media only screen and (min-width: 1456px) {
          :host([type|='standard']) {
            min-width: 160px;
          }
        }

        :host([type|='standard']) .cspl-cta {
          padding: 12px 24px;
        }

        @media only screen and (min-width: 1456px) {
          :host([type|='standard']) .cspl-cta {
            padding: 16px 24px;
          }
        }

        :host([type|='standard']) .cspl-cta::before {
          border-radius: 3px;
          border-style: solid;
          border-width: 2px;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: bottom var(--cs-interface-expansion),
            left var(--cs-interface-expansion),
            right var(--cs-interface-expansion),
            top var(--cs-interface-expansion);
          z-index: -1;
        }

        :host([type|='standard'][text-color='dark']) .cspl-cta::before {
          border-color: var(--cs-dark-color);
        }

        :host([type|='standard'][text-color='light']) .cspl-cta::before {
          border-color: var(--cs-light-color);
        }

        :host([type|='standard'][fill-color='dark']) .cspl-cta::before {
          background-color: var(--cs-dark-color);
          border-color: var(--cs-dark-color);
        }

        :host([type|='standard'][fill-color='brand']) .cspl-cta::before {
          background-color: var(--cs-theme-color);
          border-color: var(--cs-theme-color);
        }

        :host([type|='standard'][fill-color='cs-access-brand'])
          .cspl-cta::before {
          background-color: var(--cs-access-brand-color);
          border-color: var(--cs-access-brand-color);
        }

        :host([type|='standard'][fill-color='origin-brand']) .cspl-cta::before {
          background-color: var(--origin-brand-color);
          border-color: var(--origin-brand-color);
        }

        :host([type|='standard'][fill-color='xbox-brand']) .cspl-cta::before {
          background-color: var(--xbox-brand-color);
          border-color: var(--xbox-brand-color);
        }

        :host([type|='standard'][fill-color='playstation-brand'])
          .cspl-cta::before {
          background-color: var(--playstation-brand-color);
          border-color: var(--playstation-brand-color);
        }

        :host([type|='standard'][fill-color='nintendo-brand'])
          .cspl-cta::before {
          background-color: var(--nintendo-brand-color);
          border-color: var(--nintendo-brand-color);
        }

        :host([type|='standard'][stroke-color='none']) .cspl-cta::before {
          border: none;
        }

        :host([type|='standard'][stroke-color='dark']) .cspl-cta::before {
          border-color: var(--cs-dark-color);
        }

        :host([type|='standard'][stroke-color='light']) .cspl-cta::before {
          border-color: var(--cs-light-color);
        }

        :host([type|='standard'][stroke-color='brand']) .cspl-cta::before {
          border-color: var(--cs-theme-color);
        }

        :host([type|='standard'][stroke-color='cs-access-brand'])
          .cspl-cta::before {
          border-color: var(--cs-access-brand-color);
        }

        :host([type|='standard'][stroke-color='origin-brand'])
          .cspl-cta::before {
          border-color: var(--origin-brand-color);
        }

        :host([type|='standard'][stroke-color='xbox-brand']) .cspl-cta::before {
          border-color: var(--xbox-brand-color);
        }

        :host([type|='standard'][stroke-color='playstation-brand'])
          .cspl-cta::before {
          border-color: var(--playstation-brand-color);
        }

        :host([type|='standard'][stroke-color='nintendo-brand'])
          .cspl-cta::before {
          border-color: var(--nintendo-brand-color);
        }

        :host([type|='standard']) .cspl-cta:hover::before {
          bottom: -3px;
          left: -3px;
          right: -3px;
          top: -3px;
        }

        :host([type|='standard']) .cspl-cta:active::before {
          border-width: 3px;
          bottom: -4px;
          left: -4px;
          right: -4px;
          top: -4px;
        }

        :host([type='text']) {
          margin-bottom: 8px;
        }

        :host([type='text']) .cspl-cta:hover {
          color: var(--cs-theme-color);
        }

        :host([type='text'].cspl-cta-first) {
          margin-top: 0;
        }

        :host([type='text']) .cspl-cta__liner {
          position: relative;
        }

        :host([type='text']) .cspl-cta__liner::before {
          background-color: var(--cs-dark-color);
          bottom: 0;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
          transition: left var(--cs-interface-expansion),
            right var(--cs-interface-expansion);
        }

        :host([type='text'][text-color='light']) .cspl-cta__liner::before {
          background-color: var(--cs-light-color);
        }

        :host([type='text'][text-color='cs-access-brand'])
          .cspl-cta__liner::before {
          background-color: var(--cs-access-brand-color);
        }

        :host([type='text'][text-color='origin-brand'])
          .cspl-cta__liner::before {
          background-color: var(--origin-brand-color);
        }

        :host([type='text'][text-color='xbox-brand']) .cspl-cta__liner::before {
          background-color: var(--xbox-brand-color);
        }

        :host([type='text'][text-color='playstation-brand'])
          .cspl-cta__liner::before {
          background-color: var(--playstation-brand-color);
        }

        :host([type='text'][text-color='nintendo-brand'])
          .cspl-cta__liner::before {
          background-color: var(--nintendo-brand-color);
        }

        :host([type='text']) .cspl-cta__liner:hover::before {
          background-color: var(--cs-theme-color);
          left: -4px;
          right: -4px;
        }

        :host([type='text']) .cspl-cta:active::before {
          height: 3px;
        }

        @media only screen and (min-width: 768px) {
          :host(:not([type=graphic]): last-child) {
            margin-right: 0;
          }

          :host([type='graphic'])::before {
            backface-visibility: hidden;
            content: '';
            display: inline-block;
            height: 108px;
          }

          :host([type='graphic'])::before {
            border-left: 1px solid transparent;
          }

          :host([type='graphic']:not(.cspl-cta-first))::before {
            border-left-color: #a3a3a3;
          }

          :host([type='graphic'][text-color='light']:not(.cspl-cta-first))::before {
            border-color: #585858;
          }

          :host([type='graphic']) .cspl-cta__liner {
            display: inline-block;
          }

          :host([type='graphic']:not(:only-child)) .cspl-cta__liner {
            margin-left: 24px;
          }
        }

        :host([type|='standard']:not([icon='none'])) .cspl-cta__liner {
          align-items: center;
          display: flex;
          flex-direction: row-reverse;
        }

        :host([type='standard-image']) .cspl-cta__liner {
          width: 100%;
        }

        :host([type|='standard']:not([icon='none'])) .cspl-cta__icon {
          flex-shrink: 0;
          height: 16px;
          margin-left: 8px;
          width: 16px;
        }

        :host([type='graphic']:not([icon='none'])) .cspl-cta__icon,
        :host([type|='standard']:not([icon='none'])) .cspl-cta__icon {
          display: block;
        }

        :host([type='graphic']:not([icon='none'])) .cspl-cta__label {
          display: inline-block;
          margin-top: 10px;
        }

        @media only screen and (min-width: 1456px) {
          :host([type=graphic]: not([icon=none])) .cspl-cta__label {
            margin-top: 14px;
          }
        }

        :host([type|='standard'][text-color='dark']) .cspl-cta__icon {
          fill: var(--cs-dark-color);
          stroke: var(--cs-dark-color);
        }

        :host([type|='standard'][text-color='light']) .cspl-cta__icon {
          fill: var(--cs-light-color);
          stroke: var(--cs-light-color);
        }

        :host([type|='standard'][text-color='brand']) .cspl-cta__icon {
          fill: var(--cs-theme-color);
          stroke: var(--cs-theme-color);
        }

        :host([type|='standard'][text-color='cs-access-brand'])
          .cspl-cta__icon {
          fill: var(--cs-access-brand-color);
          stroke: var(--cs-access-brand-color);
        }

        :host([type|='standard'][text-color='origin-brand']) .cspl-cta__icon {
          fill: var(--origin-brand-color);
          stroke: var(--origin-brand-color);
        }

        :host([type|='standard'][text-color='xbox-brand']) .cspl-cta__icon {
          fill: var(--xbox-brand-color);
          stroke: var(--xbox-brand-color);
        }

        :host([type|='standard'][text-color='playstation-brand'])
          .cspl-cta__icon {
          fill: var(--playstation-brand-color);
          stroke: var(--playstation-brand-color);
        }

        :host([type|='standard'][text-color='nintendo-brand']) .cspl-cta__icon {
          fill: var(--nintendo-brand-color);
          stroke: var(--nintendo-brand-color);
        }

        :host([type='graphic']) .cspl-cta__icon {
          height: 40px;
          margin: 0 auto;
          transition: transform var(--cs-interface-expansion);
          width: 40px;
        }

        :host([type='graphic']:not([icon='none']):hover) .cspl-cta__icon {
          transform: scale(1.2);
        }

        :host([type='image']),
        :host([type='standard-image']) {
          line-height: 0;
          margin-bottom: 16px;
          text-indent: -99999px;
          transition: all var(--cs-interface-expansion);
        }

        :host([type='image']:hover) {
          transform: scale(1.05);
        }

        :host([type='image']) .cspl-cta__image,
        :host([type='standard-image']) .cspl-cta__image {
          display: block;
        }

        :host([type='standard-image']) .cspl-cta__image {
          height: 28px;
          margin: 0 auto;
          max-width: 100%;
          width: auto;
        }

        :host([type='arrow-left']),
        :host([type='arrow-right']) {
          background: var(--cs-light-color);
          margin: 0;
          padding: 20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: auto;
          z-index: 3;
        }

        :host([text-color='light'][type='arrow-left']),
        :host([text-color='light'][type='arrow-right']) {
          background: var(--cs-dark-color);
        }

        :host([type='arrow-left']) {
          left: 0;
        }

        :host([type='arrow-right']) {
          right: 0;
        }

        :host([text-color='brand']) .cspl-cta__arrow-left,
        :host([text-color='brand']) .cspl-cta__arrow-right {
          fill: var(--cs-theme-color);
          stroke: var(--cs-theme-color);
        }

        .cspl-cta__arrow-left,
        .cspl-cta__arrow-right {
          height: 32px;
          width: 32px;
        }

        .cspl-cta__arrow-left {
          animation: 1s 1s move-left 5;
          transform: rotate(90deg);
        }

        .cspl-cta__arrow-right {
          animation: 1s 1s move-right 5;
          transform: rotate(-90deg);
        }

        :host([full-width]) {
          width: 100%;
        }

        mwc-circular-progress {
          --mdc-theme-primary: var(--cs-theme-color);
        }
      </style>
      <template is="dom-if" if="{{loading}}">
        <mwc-circular-progress indeterminate></mwc-circular-progress>
      </template>
      <template is="dom-if" if="{{!loading}}">
        <a
          href$="[[ linkUrl ]]"
          title$="[[ linkTitle ]]"
          class="cspl-cta"
          target$="[[ target ]]"
        >
          <template is="dom-if" if="[[ _isType(type, 'arrow-left') ]]">
            <iron-icon
              icon="cs-action-16:chevron"
              class="cspl-cta__arrow-left"
            ></iron-icon>
          </template>
          <div class="cspl-cta__liner">
            <template is="dom-if" if="[[ _hasMwcIcon(iconType) ]]">
              <mwc-icon class$="[[ _getIconClass(icon) ]]" style="font-size:38px">[[ icon ]]</mwc-icon>
            </template>
            <template is="dom-if" if="[[ !_hasMwcIcon(iconType) ]]">
              <template is="dom-if" if="[[ _hasIcon(icon, type) ]]">
                <iron-icon
                  icon="{{ _getIconName(type, icon) }}"
                  class$="[[ _getIconClass(icon) ]]"
                >
                </iron-icon>
              </template>
            </template>

            <template is="dom-if" if="[[ _isEmpty(linkText) ]]">
              <span id="Label" class="cspl-cta__label">[[ linkText ]]</span>
            </template>
            <template is="dom-if" if="[[ _showImage(type) ]]">
              <cs-lazy-image>
                <img
                  class="cspl-cta__image"
                  data-src$="[[ image ]]"
                  alt="[[ alt ]]"
                />
              </cs-lazy-image>
            </template>
            <template is="dom-if" if="[[ _isType(type, 'arrow-right') ]]">
              <iron-icon
                icon="cs-action-16:chevron"
                class="cspl-cta__arrow-right"
              ></iron-icon>
            </template>
          </div>
        </a>
      </template>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  static get observers() {
    return ['_hideCta(targetOs)'];
  }

  _getIconClass(e) {
    return `cspl-cta__icon cspl-cta__icon--${e}`;
  }
  _getIconName(e, t) {
    return `cs-action-${this._getIconSize(e)}:${t}`;
  }
  _getIconSize(e) {
    let t = '';
    switch (e) {
      case 'graphic':
        t = 40;
        break;
      default:
        t = 16;
    }
    return t;
  }
  _hasMwcIcon(value) {
    return value === 'mwc-icon';
  }
  _hasIcon(e, t) {
    return 'none' !== e && 'custom' !== e && 'text' !== t;
  }
  _setFirstChildClass() {
    const e = this.parentNode.querySelector('[slot="cta"]');
    e && e.classList.add('cspl-cta-first'); // jshint ignore:line
  }
  _showImage(e) {
    return 'image' === e || 'standard-image' === e;
  }
  _getDeviceOS() {
    const e = window.navigator.userAgent;
    let t = '';
    return (
      /iPad|iPhone|iPod/.test(e)
        ? (t = 'ios')
        : /Android/.test(e) && (t = 'android'),
      t
    );
  }
  _isEmpty(value) {
    return value == 'none' ? false : true;
  }
  _hideCta(e) {
    const t = this._getDeviceOS();
    e && 'all' !== e && t && (e === t || (this.hidden = !0)); // jshint ignore:line
  }
  _setCustomIcon(e) {
    if (e && 'custom' === this.icon) {
      const t = document.createElement('span');
      const a = this.shadowRoot.querySelector('.cspl-cta__liner');
      t.innerHTML = e;
      const r = t.firstChild;
      r.setAttribute('class', this._getIconClass('custom')),
        a.insertBefore(r, a.firstChild); // jshint ignore:line
    }
  }
  _setGaEvent(e = null) {
    (this.gaEventName = '/ga/nds/cta'),
      (this.gaEventPayload = {
        context: e,
        intent: this.intent,
        text: this.linkText,
        url: this.linkUrl || this.modalId,
      }); // jshint ignore:line
  }
  _hideLinkText() {
    this.linkText || (this.$.Label.hidden = !0); // jshint ignore:line
  }
  _isType(e, t) {
    return e === t;
  }
  connectedCallback() {
    super.connectedCallback(),
      this._setCustomIcon(this.customIcon),
      this._setGaEvent(),
      this._setFirstChildClass(),
      this._hideLinkText(); // jshint ignore:line
  }
}

customElements.define('cs-cta', CsCta);
