/* jshint ignore:start */
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsCurrentLinkMixin } from './mixins/cs-current-link-mixin.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsLinkIconContainerMixin } from './mixins/cs-link-icon-container-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

const t = CsMixin,
  a = CsCurrentLinkMixin,
  r = CsGoogleAnalyticsMixin,
  n = CsLinkIconContainerMixin;

/**
 * `cs-link` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLink extends t(a(r(n(PolymerElement)))) {
  static get properties() {
    return {
      linkHref: String,
      linkTitle: String,
      linkText: String,
      type: String,
      textColor: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      context: String,
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-links"></style>
      <style>
        :host {
          color: var(--cs-dark-color);
          display: block;
          text-decoration: none;
        }
        :host([text-color='light']) {
          color: #a3a3a3;
        }
        a {
          align-items: center;
          display: flex;
        }
        a,
        a:hover {
          color: inherit;
        }
      </style>
      <a href$="[[ linkHref ]]" title$="[[ linkTitle ]]">
        <template is="dom-if" if="[[ _hasIcon(icon) ]]">
          <cs-icon-container
            id="icon"
            icon="[[ icon ]]"
            color="[[ iconColor ]]"
            background-color="[[ iconBackgroundColor ]]"
            hovered="[[ hovered ]]"
            type="[[ type ]]"
          >
          </cs-icon-container>
        </template>
        <span class="cspl-link__text">[[ linkText ]]</span>
      </a>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  static get observers() {
    return ['_setGAEvent(context, linkText, linkHref)'];
  }

  _setGAEvent() {
    var e =
        arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null,
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : '',
      n = arguments.length > 2 ? arguments[2] : void 0;
    (this.gaEventName = '/ga/nds/link'),
      (this.gaEventPayload = {
        context: e,
        text: t.trim(),
        url: n,
      }); // jshint ignore:line
  }

  _goUrl(url) {
    location.href = url;
    // window.history.pushState({}, null, this.homeLink);
    // window.dispatchEvent(new CustomEvent('location-changed'));
  }
}

customElements.define('cs-link', CsLink);
