import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements';
import { CsMixin } from './mixins/cs-mixin.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsControlPlayerMixin } from './mixins/cs-control-player-mixin.js';
import { CsHasImageMixin } from './mixins/cs-has-image-mixin.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsIframeMixin } from './mixins/cs-iframe-mixin.js';

window.nds = window.nds || {};

const r = CsMixin;
const l = CsDeviceMixin;
const c = CsControlPlayerMixin;
const d = CsHasImageMixin;
const u = CsGoogleAnalyticsMixin;
const p = CsIframeMixin;

/**
 * `cs-youtube` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsYoutube extends r(l(c(d(u(p(CsElements)))))) {
  static get properties() {
    return {
      _eventData: {
        computed: '_getEventData(videoId)',
        type: Object,
      },
      _playerUId: {
        computed: '_getPlayerUId(videoId)',
        type: String,
      },
      _player: Object,
      _playerVars: {
        computed:
          '_getPlayerVars(' +
          [
            'autoplay',
            'controls',
            'loop',
            'rel',
            'annotations',
            'playlist',
            'videoId',
            'allowfullscreen',
            'hideInfo',
          ].join(',') +
          ')',
        type: String,
      },
      _isYtApiReadyTimeoutId: Number,
      _isYtApiReadyTimeoutDelay: {
        type: Number,
        value: 100,
      },
      _ytApiUrl: {
        type: String,
        value: 'https://www.youtube.com/iframe_api',
      },
      _videoTrackingTimeoutId: Number,
      _videoTrackingTimeoutDelay: {
        type: Number,
        value: 1e3,
      },
      _videoWasPaused: Boolean,
      _playIntent: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      controls: {
        type: Number,
        value: 1,
      },
      loop: {
        type: Number,
        value: 0,
      },
      playlist: String,
      rel: {
        type: Number,
        value: 0,
      },
      annotations: {
        type: Number,
        value: 1,
      },
      videoId: String,
      hideInfo: {
        type: Number,
        value: 0,
      },
      widget: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      buttonColor: {
        type: String,
        value: 'light',
      },
    };
  }

  static get template() {
    return html`<style include="cs-media-player-styles"></style>
      <style>
        :host {
          max-height: 100vh;
        }
        :host picture {
          display: block;
        }
        iframe {
          height: 100%;
          left: 0;
          max-height: 100vh;
          position: absolute !important;
          top: 0;
          width: 100%;
        }
      </style>
      <div class="cspl-media-player__ratio">
        <div class="cspl-media-player__video-container">
          <div id$="[[ _playerUId ]]"></div>
        </div>
        <template
          is="dom-if"
          if="[[ _hasImage(coverImageSmallest,
                            coverImageSmall,
                            coverImageMedium,
                            coverImageLarge,
                            coverImageLargest) ]]"
          ><div class="cspl-media-player__cover" on-tap="play">
            <cs-lazy-picture
              ><picture
                ><source
                  media="(max-width: 320px)"
                  data-srcset$="[[ coverImageSmallest ]]" />
                <source
                  media="(max-width: 767px)"
                  data-srcset$="[[ coverImageSmall ]]" />
                <source
                  media="(max-width: 1023px)"
                  data-srcset$="[[ coverImageMedium ]]" />
                <source
                  media="(max-width: 1455px)"
                  data-srcset$="[[ coverImageLarge ]]" />
                <source
                  media="(min-width: 1456px)"
                  data-srcset$="[[ coverImageLargest ]]" />
                <img
                  class="cspl-media-player__cover-image"
                  data-src$="[[ coverImageSmallest ]]"
                  alt="[[ coverImageTooltip ]]" /></picture
            ></cs-lazy-picture>
            <div class="cspl-media-player__cover-icon">
              <iron-icon
                class="cspl-media-player__cover-icon-play"
                icon$="cs-action-192:youtube-[[ buttonColor ]]"
              ></iron-icon
              ><iron-icon
                class="cspl-media-player__cover-icon-spinner"
                icon="cs-action-24:loading"
              ></iron-icon>
            </div></div
        ></template>
      </div>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _getPlayerUId(e) {
    return 'yt-'.concat(e, '-').concat(Math.floor(9e3 * Math.random()) + 1e3);
  }
  _buildYTPlayer(e) {
    if (!this._player || 'function' != typeof this._player.playVideo) {
      var t = this.shadowRoot.querySelector('#'.concat(this._playerUId));
      this._player = new e.Player(t, {
        height: '100%',
        width: '100%',
        videoId: this.videoId,
        playerVars: this._playerVars,
        events: {
          onReady: this._setupHandleReady.bind(this),
          onStateChange: this._handleVendorEvents.bind(this),
        },
      });
    }
  }
  _setupHandleReady(e) {
    (this._player = e.target),
      this._player.setLoop(1 === this.loop),
      this._handleVendorEvents({
        data: 'ready',
      });
  }
  _isYTLoaded() {
    return 'undefined' != typeof YT && YT.Player;
  }
  _isYTLoading() {
    return 'undefined' != typeof YT && YT.loading;
  }
  _initYTLoad() {
    this._isYTLoaded()
      ? this._buildYTPlayer(YT)
      : this._isYTLoading()
      ? this._isYTApiReady()
      : this._loadYTApi(),
      this.autoplay && (this._playIntent = !0);
  }
  _loadYTApi() {
    if (!window.nds.CsYoutubeAPILoading) {
      var e = document.createElement('script'),
        t = document.getElementsByTagName('script')[0];
      (window.nds.CsYoutubeAPILoading = 1),
        (e.src = this._ytApiUrl),
        t.parentNode.insertBefore(e, t);
    }
    this._isYTApiReady();
  }
  _isYTApiReady() {
    clearTimeout(this._isYtApiReadyTimeoutId),
      (this._isYtApiReadyTimeoutId = setTimeout(() => {
        this._isYTLoaded() ? this._buildYTPlayer(YT) : this._isYTApiReady();
      }, this._isYtApiReadyTimeoutDelay));
  }
  _getPlayerVars(e, t, i, a, n, s, o, r, l) {
    var c = {
      autoplay: e,
      controls: t,
      html5: 1,
      loop: i,
      origin: '*',
      rel: a,
      iv_load_policy: n,
      fs: r ? 1 : 0,
      showinfo: l ? 0 : 1,
    };
    return (
      s &&
        1 === s.split(',').length &&
        ((c.list = s), (c.listType = 'playlist')),
      s && s.split(',').length > 1 && (c.playlist = s),
      !s && i && (c.playlist = o),
      c
    );
  }
  _getEventData(e) {
    return {
      video: e,
    };
  }
  _handleVendorEvents(e) {
    var t = '',
      i = '';
    switch (e.data) {
      case 5:
        (i = this._eventNames.READY), (t = this._eventNames.STOPPED);
        break;
      case -1:
        this.autoplay && (this._playIntent = !1);
        break;
      case 0:
        (i = this._eventNames.FINISHED),
          (t = this._eventNames.FINISHED),
          (this._videoWasPaused = !1),
          clearTimeout(this._videoTrackingTimeoutId);
        break;
      case 1:
        (t = i = this._eventNames.PLAYING),
          (this._playIntent = !1),
          this._initTracking();
        break;
      case 2:
        (t = i = this._eventNames.PAUSED),
          (this._videoWasPaused = !0),
          clearTimeout(this._videoTrackingTimeoutId);
        break;
      case 'ready':
        t = i = this._eventNames.READY;
    }
    i &&
      t &&
      i !== this.status &&
      (this._setStatus(i),
      this.dispatchEvent(
        new CustomEvent(t, {
          bubbles: !0,
          composed: !0,
          detail: this._eventData,
        })
      ),
      this._playIntent && 'ready' === i && this.play());
  }
  _initTracking() {
    if (this._videoWasPaused) {
      var e = this._videoTrackingTimeoutDelay;
      this._trackVideoProgress(e - ((1e3 * this._player.getCurrentTime()) % e));
    } else
      this.gaEvent('/ga/video/start', {
        name: this.videoId,
        provider: 'YT',
      }),
        (this._videoTrackingTimeoutDelay = (this.getDuration() / 10) * 1e3),
        this._trackVideoProgress();
  }
  _trackVideoProgress() {
    var e =
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : this._videoTrackingTimeoutDelay;
    clearTimeout(this._videoTrackingTimeoutId),
      (this._videoTrackingTimeoutId = setTimeout(() => {
        var e = (this._player.getCurrentTime() / this.getDuration()) * 100,
          t = 10 * Math.round(e / 10);
        this.gaEvent('/ga/video/range', {
          name: this.videoId,
          range: t,
          provider: 'YT',
        }),
          this._trackVideoProgress();
      }, e));
  }
  getDuration() {
    return this._player.getDuration();
  }
  play() {
    'loading' !== this.status && this._player.playVideo(),
      (this._playIntent = !0);
  }
  pause() {
    this._player.getPlayerState() === YT.PlayerState.PLAYING &&
      this._player.pauseVideo();
  }
  stop() {
    this._player.stopVideo();
  }
  _lazyLoadYoutube() {
    var e = this.shadowRoot.querySelector('.cspl-media-player__ratio'),
      t = new IntersectionObserver(
        e => {
          e.forEach(e => {
            (e.isIntersecting || e.intersectionRatio > 0) &&
              (this._initYTLoad(), t.unobserve(e.target));
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        }
      );
    t.observe(e);
  }
  connectedCallback() {
    super.connectedCallback(),
      'function' == typeof IntersectionObserver
        ? this._lazyLoadYoutube()
        : this._initYTLoad();
  }
  disconnectedCallback() {
    super.disconnectedCallback(),
      clearTimeout(this._isYtApiReadyTimeoutId),
      clearTimeout(this._videoTrackingTimeoutId);
  }
}

customElements.define('cs-youtube', CsYoutube);
