/* jshint ignore:start */
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

const t = CsMixin,
  a = CsGoogleAnalyticsMixin;

/**
 * `cs-social-link` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSocialLink extends t(a(PolymerElement)) {
  static get properties() {
    return {
      linkUrl: String,
      linkTitle: String,
      linkTarget: String,
      theme: {
        type: String,
        value: 'brand',
        reflectToAttribute: !0,
      },
      socialNetwork: {
        type: String,
        observer: '_observeSocialNetwork',
      },
    };
  }

  static get template() {
    return html`
      <style>
        :host {
          display: inline-block;
          margin-bottom: 23px;
          margin-right: 24px;
        }

        :host(:first-of-type) {
          margin-left: 0;
        }

        @media only screen and (min-width: 768px) {
          :host {
            margin-bottom: 26px;
          }
        }

        @media only screen and (min-width: 1024px) {
          :host {
            margin: 0 0 0 24px;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host {
            margin: 0 0 0 32px;
          }
        }

        a {
          text-align: -9999px;
          text-decoration: none;
        }

        iron-icon {
          fill: var(--cs-theme-color);
          height: 22px;
          transition: all 0.1s linear;
          width: 22px;
        }

        :host([theme='light']) iron-icon {
          fill: var(--cs-light-color);
        }

        :host([theme='dark']) iron-icon {
          fill: var(--cs-dark-color);
        }

        :host(:hover) iron-icon {
          transform: scale(1.272);
        }</style
      ><a
        href="[[ linkUrl ]]"
        title="[[ linkTitle ]]"
        target="[[ linkTarget ]]"
      >
        <iron-icon icon="cs-action-16:[[ socialNetwork ]]"></iron-icon>
      </a>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _observeSocialNetwork(e) {
    (this.gaEventName = '/ga/social/follow'),
      (this.gaEventPayload = {
        network: e,
      }); // jshint ignore:line
  }
}

customElements.define('cs-social-link', CsSocialLink);
