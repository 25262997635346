/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";

/**
 * `cs-social-links` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSocialLinks extends PolymerElement {
	static get properties() {
		return {
			textColor: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			titleText: String,
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-typography-labels"></style>
			<style>
				:host {
					border-top: 1px solid #969696;
					display: block;
					padding-top: 4px;
				}

				:host([text-color="light"]) {
					border-top: 1px solid #595959;
				}

				@media only screen and (min-width: 768px) {
					:host {
						border-top: none;
						padding-top: 0;
					}

					:host([text-color="light"]) {
						border-top: none;
					}
				}

				.social-links-title {
					color: #161616;
					margin-bottom: 18px;
				}

				:host([text-color="light"]) .social-links-title {
					color: #f7f7f7;
				}

				@media only screen and (min-width: 768px) {
					.social-links-title {
						margin: 17px 0;
					}
				}

				@media only screen and (min-width: 1024px) {
					.social-links-title {
						margin: 24px 0 9px;
						text-align: right;
					}
				}

				@media only screen and (min-width: 1456px) {
					.social-links-title {
						margin: 32px 0 13px;
					}
				}

				@media only screen and (min-width: 1024px) {
					.social-links {
						display: flex;
					}
				}
			</style>
			<h3 class="social-links-title l2">[[ titleText ]]</h3>
			<div class="social-links">
				<slot name="social-link"></slot>
			</div> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
}

customElements.define("cs-social-links", CsSocialLinks);
