import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsBodyScrollMixin } from './mixins/cs-body-scroll-mixin.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';
import { CsPreventTouchScrollMixin } from './mixins/cs-prevent-touch-scroll-mixin.js';
import { CsRatioFitMixin } from './mixins/cs-ratio-fit-mixin.js';

const C = CsMixin,
  n = CsBodyScrollMixin,
  h = CsRatioFitMixin,
  g = CsPreventTouchScrollMixin,
  r = CsDeviceMixin;
var M = {
    'cs-youtube': 'https://www.youtube.com',
    'cs-vimeo': 'https://player.vimeo.com',
    'cs-twitch': 'https://player.twitch.tv',
    'cs-soundcloud': 'https://w.soundcloud.com',
  },
  f = 'nds.modal.open',
  s;
/**
 * `cs-modal` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsModal extends C(n(h(g(r(CsElements))))) {
  static get properties() {
    return {
      id: String,
      theme: {
        type: String,
        value: '',
        reflectToAttribute: !0,
      },
      hidden: {
        type: Boolean,
        value: 1,
        reflectToAttribute: !0,
      },
      displayed: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      type: {
        type: String,
        value: 'railed',
      },
      _fitObject: Object,
      scrollable: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
      <style>
        :host {
          display: Block;
          height: 100%;
          left: 0;
          margin: 0;
          max-height: 100%;
          max-width: 100%;
          opacity: 1;
          padding: var(--cs-gutter-mobile) 0;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: var(--cs-modal-layer);
        }
        @media only screen and (min-width: 768px) {
          :host {
            padding: var(--cs-gutter-tablet-portrait) 0;
          }
        }
        @media only screen and (min-width: 1024px) {
          :host {
            padding: var(--cs-gutter-tablet-landscape) 0;
          }
        }
        :host([aria-hidden='true']) {
          display: block !important;
          opacity: 0;
          z-index: -1;
        }
        :host([theme='dark']) {
          background-color: #000;
        }
        :host([theme='light']) {
          background-color: #fff;
        }
        .cspl-modal__liner {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          overflow: hidden;
          position: relative;
        }
        .cspl-modal__header {
          align-items: center;
          display: flex;
          flex-basis: 56px;
          flex-shrink: 0;
          justify-content: flex-end;
          margin-bottom: 24px;
          width: 100%;
        }
        @media only screen and (min-width: 1024px) {
          .cspl-modal__header {
            margin-bottom: 28px;
          }
        }
        @media only screen and (min-width: 1456px) {
          .cspl-modal__header {
            margin-bottom: 32px;
          }
        }
        .cspl-modal__close {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: calc(24px + var(--cs-gutter-mobile) * 2);
          justify-content: center;
          transform: translateY(-50%);
          transition: transform 0.1s ease-in;
          width: calc(24px + var(--cs-gutter-mobile) * 2);
        }
        :host([displayed]) .cspl-modal__body,
        :host([displayed]) .cspl-modal__close {
          transform: translateY(0);
          transition-duration: 0.25s;
          transition-timing-function: ease-out;
        }
        @media only screen and (min-width: 768px) {
          .cspl-modal__close {
            margin: 0
              calc(var(--cs-gutter-tablet-portrait) - var(--cs-gutter-mobile));
          }
        }
        @media only screen and (min-width: 1024px) {
          .cspl-modal__close {
            margin: 0
              calc(var(--cs-gutter-tablet-landscape) - var(--cs-gutter-mobile));
          }
        }
        .cspl-modal__close-icon {
          pointer-events: none;
        }
        :host([theme='dark']) .cspl-modal__close-icon {
          fill: var(--cs-light-color);
        }
        :host([theme='light']) .cspl-modal__close-icon {
          fill: var(--cs-dark-color);
        }
        .cspl-modal__body {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          flex-basis: calc(100% - 80px);
          height: calc(100% - 80px);
          justify-content: center;
          max-height: calc(100% - 80px);
          min-height: calc(100% - 80px);
          padding: 0 var(--cs-gutter-mobile);
          position: relative;
          transform: translateY(-10%);
          transition: transform 0.25s ease-in;
          width: 100%;
        }
        :host(:-webkit-full-screen-ancestor) .cspl-modal__body {
          transform: none;
          transition: none;
        }
        @media only screen and (min-width: 768px) {
          .cspl-modal__body {
            padding: 0 var(--cs-gutter-tablet-portrait);
          }
        }
        @media only screen and (min-width: 1024px) {
          .cspl-modal__body {
            flex-basis: calc(100% - 84px);
            height: calc(100% - 84px);
            max-height: calc(100% - 84px);
            min-height: calc(100% - 84px);
            padding: 0 var(--cs-gutter-tablet-landscape);
          }
        }
        @media only screen and (min-width: 1456px) {
          .cspl-modal__body {
            flex-basis: calc(100% - 88px);
            height: calc(100% - 88px);
            max-height: calc(100% - 88px);
            min-height: calc(100% - 88px);
          }
        }
        :host([type='full-bleed']) .cspl-modal__body {
          padding-left: 0;
          padding-right: 0;
        }
        .cspl-modal__ratio {
          align-items: flex-start;
          display: flex;
          height: 100%;
          justify-content: center;
          width: 100%;
        }
        :host([type='full-bleed']) .cspl-modal__ratio {
          align-items: stretch;
          overflow: auto;
        }
        :host([scrollable]) .cspl-modal__ratio {
          overflow-y: auto;
        }
        :host([type='railed'][scrollable])
          .cspl-modal__ratio
          ::slotted(cs-section) {
          margin: auto;
        }
      </style>
      <div class="cspl-modal__liner">
        <div
          style="display: flex;justify-content: space-between;width: 100%;flex-direction: row;"
        >
          <h4 style="padding: 12px 0 0 24px;margin: 0;margin-bottom: 28px;">
            {{title}}
          </h4>
          <div class="cspl-modal__header">
            <a class="cspl-modal__close" on-tap="_handleClose"
              ><iron-icon
                class="cspl-modal__close-icon"
                icon="cs-action-16:close"
              ></iron-icon
            ></a>
          </div>
        </div>
        <div class="cspl-modal__body">
          <div class="cspl-modal__ratio">
            <slot name="image"></slot><slot name="soundcloud"></slot>
            <slot name="twitch"></slot><slot name="vimeo"></slot>
            <slot name="youtube"></slot><slot name="html5-video"></slot>
            <slot name="age-gate"></slot><slot name="media"></slot>
            <slot name="section"></slot>
          </div>
        </div>
      </div>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super(),
      (this._handleInvokerOpen = this._handleInvokerOpen.bind(this)),
      (this._handleOpen = this._handleOpen.bind(this)),
      (this._handleKeyUp = this._handleKeyUp.bind(this));
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _pauseMedia() {
    [
      ...this.querySelectorAll(
        'cs-soundcloud, cs-twitch, cs-vimeo, cs-youtube, cs-html5-video'
      ),
    ].forEach(L => {
      L && 'function' == typeof L.pause && L.pause();
    });
  }
  _handleOpen() {
    (this.displayed = !0),
      this._preventScroll(),
      document.addEventListener('keyup', this._handleKeyUp),
      this.setAttribute('aria-hidden', !this.displayed);
    this._addMessageEvent();
  }
  _handleClose() {
    (this.displayed = !1),
      // this._pauseMedia(),
      this._addScroll(),
      document.removeEventListener('keyup', this._handleKeyUp),
      window.removeEventListener('message', s),
      (this._animationDelay = setTimeout(() => {
        this.close();
      }, 250));
  }

  close() {
    this.setAttribute('aria-hidden', !this.displayed);
  }

  _handleKeyUp(L) {
    L.keyCode === 27 && this._handleClose();
  }
  _handleMessage() {
    var L = arguments[0],
      e = M[L],
      t = arguments[1];
    t instanceof window.MessageEvent && t.origin === e && this.focus();
  }
  _addMessageEvent() {
    var L = this.shadowRoot.querySelector(
      'cs-soundcloud, cs-twitch, cs-vimeo, cs-youtube'
    );
    L &&
      ((s = this._handleMessage.bind(this, L.nodeName.toLowerCase())),
      window.addEventListener('message', s));
  }
  _onResize() {
    'full-bleed' === this.type ||
      !this._fitObject ||
      (this._hasSection && this.isTouch) ||
      this._fit(this._fitObject);
  }
  getSlottedChildren(e) {
    var t =
        arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [''],
      { shadowRoot: a } = e;
    return a
      ? t.reduce((e, t) => {
          var l = a.querySelector(
            t.length ? 'slot[name="'.concat(t, '"]') : 'slot'
          );
          if (!l) return e;
          var o = l
            .assignedNodes({
              flatten: !0,
            })
            .filter(e => e.nodeType === Node.ELEMENT_NODE);
          return [...e, ...o];
        }, [])
      : [];
  }
  _hasSection() {
    return !!this.getSlottedChildren(this, ['section'])[0];
  }
  _handleInvokerOpen(L) {
    L.detail && L.detail.modalId === this.id && this.open();
  }
  connectedCallback() {
    super.connectedCallback(),
      document.addEventListener(f, this._handleInvokerOpen),
      this.addEventListener('iron-overlay-opened', this._handleOpen),
      this.addEventListener('iron-overlay-closed', this._handleClose),
      setTimeout(() => {
        (this.scrollable = this._hasSection()),
          'full-bleed' === this.type ||
            (this.scrollable && this.isTouch) ||
            ((this._fitObject =
              this.shadowRoot.querySelector('.cspl-modal__ratio')),
            this._fit(this._fitObject),
            this._preventTouchScroll(this.isTouch));
      });
  }
  disconnectedCallback() {
    super.disconnectedCallback(),
      document.removeEventListener(f, this._handleInvokerOpen),
      this._addScroll(),
      clearTimeout(this._animationDelay);
  }
}

customElements.define('cs-modal', CsModal);
